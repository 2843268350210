import Vue from 'vue'
import axios from 'axios'
// import router from './router.js'

// 设置基础URL

const productionTip = false// 正式上线需为 TRUE

let baseURL, resourceURL, imgURL

if(productionTip) {
	// 生产环境
	baseURL = "https://api.ruisen888.com/apis/"
	resourceURL = "https://api.ruisen888.com/resource/"
	imgURL = "https://static.ruisen888.com/"
} else {
	// 测试环境
	baseURL = "https://api.sinochao.com/apis/"
	resourceURL = "https://api.sinochao.com/resource/"
	imgURL = "https://static.sinochao.com/"
}

//	正式环境API
axios.defaults.baseURL = baseURL
axios.defaults.resourceURL = resourceURL
axios.defaults.imgURL = imgURL

// 设置请求超时时间
axios.defaults.timeout = 25000

// 设置请求头信息
axios.defaults.headers = Object.assign(axios.defaults.headers, {
	'Content-Type': 'multipart/form-data'
})

// POST参数检查及设置
axios.interceptors.request.use((config) => {

	// 创建一个上传参数的 FormData 对像
	var formData = new FormData()
	
	// 检查请求参数
	if (config.data != undefined) {
		/*		请求数据非空，需合参数		*/
		if (config.data.append == undefined) {
			// 请求数据为 Object 对像，遍历装进 FormData 对像
			for (var i in config.data) {
				formData.append(i, config.data[i])
			}
		} else {
			// 请求数据为 FormData 对像，遍历装进 FormData 对像
			var entries = config.data.entries()
			var data = entries.next()
			
			while (data.done == false) {
				formData.append(data.value[0], data.value[1])
				data = entries.next()
			}
		}
		/*		请求数据非空，需合参数		*/

	} 
	
	config.data = formData
	
	return config
}, (error) => {
    return Promise.reject(error);
}); 
 
// 返回状态判断 
axios.interceptors.response.use((res) =>{ 
	if (res.data.success || res.data.type == 'application/octet-stream') {
		return res.data
	} else {
		if (res.data.error_code == 1000) {
			return res.data
		} else {
			switch (res.data.error_code) {
				case 1: 
					//	TOKEN无效
					
					// router.push('/nologin')
					
					return {
						success: false,
						error_text: '登录已失效，请重新登录'
					}
					
				default :
					if(res.data.error_text&&res.data.error_text!=''){
						return {
							success: false,
							error_text:res.data.error_text+'999'
						}
					}else{
						return {
							success: false,
							error_text: '请求出错啦，请稍等一会再试试'
						}
					}
					
			}
		}
	}
}, (error) => {
	//404等问题可以在这里处理 
	return Promise.reject(error); 
})

Vue.prototype.$axios = axios

export default axios